<template>
  <section :class="['game', { 'mobile': this.game.mobile }]">
    <div class="thumbnail-wrapper">
      <div class="thumbnail-highlight-wrapper">
        <img :src="`/images/games-showcase/${this.game.images[this.game.activeImage]}`" :alt="this.game.name" />
        <div class="golden-tag" v-if="this.game.gameDevTeam">
          <div class="inner-wrapper">
            <span>Game Dev Team</span>
          </div>
        </div>
      </div>
      <div class="image-feed-wrapper">
        <img :class="{ 'active': index === this.game.activeImage }" :src="`/images/games-showcase/${image}`"
          alt="" v-for="(image, index) in this.game.images" :key="index"
          :onclick="() => this.game.activeImage = index" />
      </div>
    </div>
    <div class="game-content-wrapper">
      <h2>{{ this.game.name }}</h2>
      <ul class="tags list">
        <li v-for="tag in this.game.tags" :key="tag">{{ tag }}</li>
      </ul>
      <div class="content-item-wrapper">
        <h3>Description</h3>
        <p class="description">{{ this.game.description }}</p>
      </div>
      <div class="content-item-wrapper" v-if="this.game.team">
        <h3>Team</h3>
        <p><span v-for="(member, i) in this.game.team">{{ `${member}${i !== this.game.team.length - 1 ? ', ' : ''}` }}</span></p>
      </div>
      <div class="content-item-wrapper" v-if="this.game.downloadLink || this.game.downloadLinks || this.game.playLink || this.game.itchLink">
        <h3>Play it here!</h3>
        <div class="list">
          <Button
            icon="download"
            iconStyle="fas"
            iconPosition="left"
            :link="this.game.downloadLink"
            target="_blank"
            v-if="this.game.downloadLink && !Array.isArray(this.game.downloadLink)"
          >Download</Button>
          <Button
            :icon="link.icon"
            :iconStyle="link.iconStyle"
            iconPosition="left"
            :link="link.link"
            target="_blank"
            v-if="this.game.downloadLinks"
            v-for="(link) in this.game.downloadLinks"
          >{{ link.name }}</Button>
          <Button icon="play" iconStyle="fas" iconPosition="left" :link="`/game/${$route.params.gameId}/play`" v-if="this.game.playLink">Play</Button>
          <Button icon="itch-io" iconStyle="fab" iconPosition="left" :link="this.game.itchLink" target="_blank" v-if="this.game.itchLink">View on itch.io</Button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Button from '@/components/ui/Button.vue'

export default {
  name: "GamesShowcaseSingle",
  components: {
    Button
  },
  data() {
    return {
      games: this.$store.state.games,
    }
  },
  computed: {
    game() {
      const allGames = this.$store.state.games
      const gameId = parseInt(this.$route.params.gameId)
      const game = allGames.find(g => g.id === gameId)
      return game
    }
  },
  methods: {
  }
};
</script>

<style scoped lang="scss">
a {
  color: #fff;
  text-decoration: none;
  opacity: 1;
  transition: opacity 150ms ease;

  &:hover {
    opacity: 0.6;
  }
}

.game {
  display: flex;
  gap: 4rem;

  h2 {
    font-size: 2rem;
  }

  .thumbnail-wrapper {
    position: relative;
    flex-basis: 60%;
    width: 100%;

    img {
      width: 100%;
      aspect-ratio: 16/9;
      object-fit: cover;
      border-radius: 10px;
    }

    .golden-tag {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      left: 0;
      top: 0;
      width: 180px;
      height: 40px;
      background: linear-gradient(90deg, #fd9f13, #fcb045, #e8c64b);
      font-weight: 500;
      text-transform: uppercase;
      border-radius: 10px 0 10px 0;
      overflow: hidden;

      &::before {
        content: '';
        position: absolute;
        width: 110%;
        aspect-ratio: 1/1;
        background-image: conic-gradient(transparent, transparent, transparent, #f4ecce);
        animation: glow 4s linear infinite;
      }

      &::after {
        content: '';
        position: absolute;
        width: 110%;
        aspect-ratio: 1/1;
        background-image: conic-gradient(transparent, transparent, transparent, #f4ecce);
        animation: glow 4s linear infinite;
        animation-delay: -2s;
      }

      .inner-wrapper {
        position: absolute;
        z-index: 1;
        inset: 2px;
        background: linear-gradient(90deg, #fd9f13, #fcb045, #e8c64b);
        border-radius: 10px 0 10px 0;

        span {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          white-space: nowrap;
        }
      }

      @keyframes glow {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }
  }

  &.mobile .thumbnail-wrapper {
    .thumbnail-highlight-wrapper {
      position: relative;
      height: 500px;

      img {
        position: absolute;
        top: 0;
        left: 50%;
        aspect-ratio: unset;
        transform: translateX(-50%);
        width: auto;
        height: 500px;
        object-fit: contain;
      }
    }
  }

  .game-content-wrapper {
    flex-basis: 40%;
  }

  a {
    color: #fff;
    text-decoration: none;
    opacity: 1;
    transition: opacity 150ms ease;

    &:hover {
      opacity: 0.6;
    }
  }

  .content-item-wrapper {
    margin-top: 1rem;

    h3 {
      margin-bottom: 0.5rem;
    }

    p {
      opacity: 0.5;
      line-height: 1.3;
    }
  }

  .tags {
    display: block;
    padding: 0;
    margin-top: 1rem;

    & > * {
      padding: 7px 12px;
      background-color: #444455;
      list-style-type: none;
      border-radius: 4px;
      font-size: 14px;
    }
  }

  .list > * {
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 12px;
  }

  .image-feed-wrapper {
    display: flex;
    gap: 14px;
    margin-top: 1.5rem;

    img {
      flex-basis: 34%;
      width: 10%;
      border-radius: 8px;
      aspect-ratio: 16/9;
      object-fit: cover;
      border: 3px solid rgba(0, 0, 0, 0);
      outline: 1px solid rgba(255, 255, 255, 0);
      opacity: 0.6;
      cursor: pointer;
      transition: opacity 200ms ease, outline 200ms ease;

      &.active {
        opacity: 1;
        outline: 1px solid rgba(255, 255, 255, 1);
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .game {
    display: block;

    .game-content-wrapper {
      margin-top: 2rem;
    }
  }
}
</style>